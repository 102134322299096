import { Component } from '@angular/core';
import { environment } from '../../environments/environment.prod'
@Component({
  selector: 'app-header',
  standalone: true,
  imports: [],
  templateUrl: './header.component.html',
  styleUrl: './header.component.sass'
})
export class HeaderComponent {
  industryLoginURL = environment.industryLoginUrl;
  fdaLoginURL = environment.fdaLoginUrl;
  constructor(){}
}
