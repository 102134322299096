<div class="container banner">
    <div class="row">
        <div class="col-sm banner-content">
            <h3 class="h3">Electronic Submission Gateway Next Generation (ESG NextGen)</h3>
            <p class="p">
                ESG NextGen is the latest electronic information transmission hub to the FDA, providing a 
                comprehensive Agency-wide solution for submitting electronic regulatory submissions securely. 
                It facilitates the review of both premarket and post market regulatory data through a modern platform
                equipped with new features, ensuring submissions reach the appropriate FDA Center or Office. 
            </p>
        </div>    
    </div>
</div>

<div class="container content">
    <div class="row welcome-section-row">    
        <div class="col-sm welcome-section">
            <h2>Welcome to ESG NextGen! The FDA is pleased to conduct User Acceptance Testing (UAT) of the ESG NextGen system. If you are a registered UAT participant, you may begin testing by following the
                steps below:
            </h2>
        </div>
    </div>    
    <div class=" row steps-resources">
        <div class="col-12 col-md-4 col-sm-6 steps-area">
            <div class=" step">
                <div class="step-label">
                    <h2>STEP 1</h2>
                </div>
                <div class="step-info">
                    <h2>Download the necessary UAT materials</h2>
                    <h3>USP Materials</h3>
                    <div><a href="../../assets/documents/ESG NextGen UAT Guide for Industry - USP.pdf" download="ESG NextGen UAT Guide for Industry - USP.pdf">User Acceptance Testing Guide</a><p>&ensp;[PDF]</p></div>
                    <div><a href="../../assets/documents/ESG NextGen UAT Cases - Wave 2.xlsx" download="ESG NextGen UAT Cases - Wave 2.xlsx">User Acceptance Test Cases</a><p>&nbsp;[MS Excel]</p></div>
                    <div><a href="../../assets/documents/ESG NextGen UAT Wave 3 - Whats New.docx" download="ESG NextGen UAT Wave 3 - Whats New.docx">What's New - Wave 3</a><p>&nbsp;[MS Doc]</p></div>
                    <div><a href="../../assets/documents/End-to-End_Testing_Schedule_with_Centers.pdf" download="End-to-End_Testing_Schedule_with_Centers.pdf">Center Submission Timeframes - Wave 3</a><p>&nbsp;[PDF]</p></div>
                    <h3>API Materials</h3>
                    <div><a href="../../assets/documents/ESG NextGen UAT Guide for Industry - API.pdf" download="ESG NextGen UAT Guide for Industry - API.pdf">User Acceptance Testing Guide</a><p>&ensp;[PDF]</p></div>
                    <div><a href="../../assets/documents/ESG NextGen UAT Cases API.xlsx" download="ESG NextGen UAT Cases API.xlsx">User Acceptance Test Cases</a><p>&nbsp;[MS Excel]</p></div>
                    <div><a href="../../assets/documents/ESGNG.zip" download="ESGNG.zip">Files for API Testing</a><p>&ensp;[Zip]</p></div>
                    <h3>AS2 Materials</h3>
                    <div><a href="../../assets/documents/AS2 Setup Instructions.pdf" download="AS2 Setup Instructions.pdf">AS2 Set Up Instructions</a><p>&ensp;[PDF]</p></div>

                    
                </div>
            </div>
            <div class="step">
                <div class="step-label">
                    <h2>STEP 2</h2>
                </div>
                <div class="step-info">
                    <h2>Log into the UAT environment</h2>
                    <div class="button"><a href="https://appian-uat-dsc.fda.gov/suite/?signin=esgnguat" class="login">INDUSTRY LOG IN</a></div>
                </div>
            </div>
            <div class="step">
                <div class="step-label">
                    <h2>STEP 3</h2>
                </div>
                <div class="step-info">
                    <h2>Follow test case instructions for the scenarios you are interested in testing</h2>
                </div>
            </div>
            <div class="step">
                <div class="step-label">
                    <h2>STEP 4</h2>
                </div>
                <div class="step-info">
                    <h2>Email your test results to <a href="mailto:ESGNGSupport@fda.hhs.gov">ESGNGSupport&#64;fda.hhs.gov</a></h2>
                </div>
            </div>            
        </div>
        <div class="col-12 col-md-4 col-sm-6 additional-info">
            <div class="training-videos">
                <h2>UAT Training Video</h2>
                <h3>USP Videos</h3>
                <p>View the full <a href="https://www.youtube.com/watch?v=MBfSAbuswSs">ESG NextGen UAT Training video</a>&nbsp;or click one of the timestamps below to access a specific chapter:</p>
                <div><a href="https://www.youtube.com/watch?v=MBfSAbuswSs&t=0s">&ensp;0:00</a><p>Welcome to ESG NextGen</p></div>
                <div><a href="https://www.youtube.com/watch?v=MBfSAbuswSs&t=25s">&ensp;0:25</a><p>User Registration</p></div>
                <div><a href="https://www.youtube.com/watch?v=MBfSAbuswSs&t=406s">&ensp;6:46</a><p>Create Submission by USP</p></div>
                <div><a href="https://www.youtube.com/watch?v=MBfSAbuswSs&t=480s">&ensp;8:00</a><p>Acknowledgements and Submission History</p></div>
                <div><a href="https://www.youtube.com/watch?v=MBfSAbuswSs&t=676s">11:16</a><p>Create Submission by API</p></div>
                <div><a href="https://www.youtube.com/watch?v=MBfSAbuswSs&t=713s">11:53</a><p>User Management: My Information and Edit Company</p></div>
                <div><a href="https://www.youtube.com/watch?v=MBfSAbuswSs&t=882s">14:42</a><p>User Management: Pending Users</p></div> 
                <div><a href="https://www.youtube.com/watch?v=MBfSAbuswSs&t=954s">15:54</a><p>User Management: Reviewed Users and Role Management</p></div> 
                <p>View the <a href="https://www.youtube.com/watch?v=7IzDzrvzV4Y">Instructional Video for Legacy ESG User Account Registration</a></p>
                <h3>API Videos</h3>
                <p>View the <a href="https://www.youtube.com/watch?v=N7BBrc8iAF4">API UAT training video</a></p>
            </div>
            <div class="industry-demonstrations">
                <h2>Industry Demonstrations</h2>
                <a href="https://fda.zoomgov.com/rec/share/5j7ksTCwTwSVguDZSpFawON10ePfKf_stD7ORl39i9AEXmjefNtoF5dug1haKqPi.OhMzccVxArEzV7oT">Demo 1 - March 27, 2024</a>
                <a href="https://fda.zoomgov.com/rec/share/VngjyudFsSXyDgE1eiYGauxzJQ3r-TkKjf7YNRmOQjy_LRf7ofaiH2f1J3pBcnO0.boLAy9AvhqSl0BV4">Demo 2 - April 4, 2024</a>
                <a href="https://fda.zoomgov.com/rec/share/rDAMCvGVRpQZoCxj8iPX0v3_qcKjEtKuZobn9S7y6W1idUAAWQ0gzqg_5xtr9qPT.GNBvXhWWPHJal2zz">Wave 1 Industry Kickoff Recording - July 15, 2024</a>
                <a href="https://fda.zoomgov.com/rec/share/oVT7PC2ClqZeH3y_GuuKAtQ98Iq3_SW2ioTfM3hXjQ_nUHbVsgXN78DxPxrdsIo2.O1ZKd4wYzQdD_vic">Wave 1 Industry Kickoff Recording - July 17, 2024</a>
                <a href="https://fda.zoomgov.com/rec/share/PQFu-8OB8cdg7ibGgwjTP4_JKScWVD_wzcfb7q3jnDNlqlIIThZSMUuzE2WZWnkz.4LhNKYBbAHpUeqI8">Wave 2 Industry Kickoff Recording - August 26, 2024</a>
                <a href="https://fda.zoomgov.com/rec/share/SSGVDX5LFDHVm5TU5wwDQbLwaxV1isVSkAwdW7a5MRQrzbimCDVc82k_hq_GZmhA.hmyIfaRTnH7FwwOQ">Wave 2 Industry Kickoff Recording - August 28, 2024</a>
                <a href="https://fda.zoomgov.com/rec/share/WMxSrn1ROsQ3GW7Gr6nF42bCLKasLp1_VdDDzACLcnqA_-cebF64tgTRNJoVHtK-.HwDIHua06tKoNl14">Wave 3 Industry Kickoff Recording - October 21, 2024</a>
                <a href="https://fda.zoomgov.com/rec/share/VKqz20o3-ZXLSgru3AIW9LpbiiGg7POx6uCM43yJY-bsVjl4EBRvVcoFuzx2w0xG.7SEcDwNg99RDrdtd">Wave 3 Industry Kickoff Recording - October 23, 2024</a>
            </div>
        </div>
    </div>
    <div class="uat-help">
        <h2>UAT Help/Support</h2>
        <div>
            <p>
                If you encounter issues with the application, required plugins, or need
                assistance with UAT, complete the <a href="../../assets/documents/ESG NextGen Support Request.docx" download="ESG NextGen Support Request.docx">ESG NextGen Software Issue Report Form</a>
                and email it to <a href="mailto:ESGNGSupport@fda.hhs.gov">ESGNGSupport&#64;fda.hhs.gov</a>
            </p>
        </div>
        <h2>Other Helpful Information</h2>
        <div>
            <p>
                Access Questions & Answers (Q&A) from UAT Drop-In help sessions: <a href="../../assets/documents/Industry Drop In Q&A - July 24 2024.pdf" download="Industry Drop In Q&A - July 24 2024.pdf">Q&A from Jul 24, 2024 Session</a>
                 | <a href="../../assets/documents/ESG NextGen Industry UAT Wave 1 Drop-in Session 2 Q&A.pdf" download="ESG NextGen Industry UAT Wave 1 Drop-in Session 2 Q&A.pdf">Q&A from Aug 1, 2024 Session </a> 
                 | <a href="../../assets/documents/ESG NextGen Industry UAT Wave 1 Session 3 Q&A.pdf" download="ESG NextGen Industry UAT Wave 1 Session 3 Q&A.pdf">Q&A from Aug 6, 2024 Session</a>
                 | <a href="../../assets/documents/ESG NextGen Industry UAT Wave 2 Q&A - all sessions.pdf" download="ESG NextGen Industry UAT Wave 2 Q&A - all sessions.pdf">Q&A from All Wave 2 Sessions</a>
            </p>
            
        </div>        
    </div>
</div>

