<div class="container container-fluid footer-upper-container">
    <div class="row footer-upper-row">
            <div class="col-sm footer-upper-col-sm">
                <h5><a href="https://www.fda.gov/about-fda/about-website/fdagov-archive">FDA Archive</a></h5>
                <h5><a href="https://www.fda.gov/about-fda">About FDA</a></h5>
                <h5><a href="https://www.fda.gov/about-fda/about-website/internet-accessibility">Accessibility</a></h5>

            </div>
            <div class="col-sm footer-upper-col-sm">
                <h5><a href="https://www.fda.gov/about-fda/visitor-information">Visitor Information</a></h5>
                <h5><a href="https://www.fda.gov/about-fda/about-website/website-policies">Website Policies / Privacy</a></h5>
                <h5><a href="https://www.fda.gov/about-fda/jobs-and-training-fda/no-fear-act">No FEAR Act</a></h5>
                <h5><a href="https://www.hhs.gov/vulnerability-disclosure-policy/index.html">Vulnerability Disclosure Polocy</a></h5>
            </div> 
            <div class="col-sm footer-upper-col-sm">
                <h5><a href="https://www.fda.gov/">FDA</a></h5>
                <h5><a href="https://www.hhs.gov/">HHS.gov</a></h5>
                <h5><a href="https://www.usa.gov/">USA.gov</a></h5>                
            </div>  
    </div>
<div class="container footer-lower-container">
    <div class="row footer-lower-row">
        <div class="col-sm d-flex footer-lower-col-sm-left">
        <a class="a footer-lower-a" href="https://www.fda.gov/" class="footer-button">Contact FDA</a>
        <a class="a footer-lower-a" href="https://www.facebook.com/FDA" target="_blank"><span class="hide-text">facebook</span><i class="fab fa-facebook-f"></i></a>
        <a class="a footer-lower-a" href="https://x.com/US_FDA" target="_blank"><span class="hide-text">twitter</span><i class="fa-brands fa-x-twitter"></i></a>
        <a class="a footer-lower-a" href="https://instagram.com/FDA" target="_blank"><span class="hide-text">instagram</span><i class="fab fa-instagram"></i></a>
        <a class="a footer-lower-a" href="https://www.linkedin.com/company/fda/" target="_blank"><span class="hide-text">linkedin</span><i class="fab fa-linkedin-in"></i></a>
        <a class="a footer-lower-a" href="https://youtube.com/@US_FDA" target="_blank"><span class="hide-text">youtube</span><i class="fab fa-youtube"></i></a>
        <a class="a footer-lower-a" href="https://www.fda.gov/about-fda/contact-fda/subscribe-podcasts-and-news-feeds"><span class="hide-text">feeds</span><i class="fa fa-rss"></i></a>
        </div>
        <div class="col-sm footer-lower-col-sm-center">
            <img class="img-fluid footer-lower-img" src="assets/images/lp-footer-fda-logo.PNG" alt="Footer FDA logo">
        </div>
        <div class="col-sm d-flex footer-lower-col-sm-right">
            <p class="p m-auto footer-lower-p"><i class="fa fa-phone footer-lower-i"></i> 1-888-INFO-FDA (1-888-463-6332)</p>
        </div>
    </div>
</div>