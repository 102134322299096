<!-- Below code is to enable/disable uat notification banner -->
<!-- <div class="banner">
    Thank you for your participation in ESG NextGen UAT. UAT is currently unavailable.
</div> -->
<div class="container header-container">
    <div class="row header-row">    
        <div class="col-sm header-col-sm-left">    
            <img class="img-fluid header-img"src="assets/images/FDA-ESG-NextGen-logo-horizontal-header.png" alt="Header Logo"> 
        </div>
        <div class="col-sm header-col-sm-right">    
            <a href="https://appian-uat-dsc.fda.gov/suite/?signin=esgnguat" class="btn d-sm-inline d-md-block sign-in-btn">INDUSTRY LOG IN</a>
            <!-- <a href="{{fdaLoginURL}}" class="btn d-sm-inline d-md-block sign-in-btn">INDUSTRY LOG IN</a> -->
        </div>
    </div>
</div>
